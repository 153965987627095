#opt-status-indicator-container {
    position: absolute;
    width: 5rem;
    right: calc(3vw + 5rem);
    top: 10vh;
    display: flex;
}

.sphere {
    width: 1rem;
    height: 1rem;
}
