.optimization-progress {
    position: absolute;
    left: 2vw;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px silver solid;
    background: white;
    z-index: 15;
    max-width: max(15vw, 220px);
}
