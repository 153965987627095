:root {
  --corp-main-color: 253 126 20; /*#fd7e14;*/
  --corp-secondary-color: 210, 255, 77;
}

body {
  overflow: hidden;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-flow-container {
  /*width: 80%;*/
  height: 95%;
  padding: 1%;
  background: #f0f0f0;
  border: solid 1px silver;
  border-radius: 3px;
  display: flex;
  justify-content: center;

  text-align: center;
  align-items: center;
  flex-direction: column;
}
.react-flow {
  background: #fff;
}

.MuiPopover-root {
  /*z-index: 10 !important;*/
  /*}*/
}
.header-form {
  font-size: 1.5rem !important;
  color: rgba(var(--corp-main-color)) !important;
}

.MuiTabPanel-root {
  height: 90vh;
  width: 100vw;
}


.MuiAppBar-root{

  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
}

.MuiAppBar-root h1 {
  margin: 20px;
}

.MuiTabs-flexContainer {
  height: 100%;
}

.MuiAppBar-colorPrimary{
  background-color: rgb(var(--corp-main-color)) !important
}

.PrivateTabIndicator-colorSecondary-3 {
  background-color: rgb(var(--corp-secondary-color)) !important;
}

h1 svg {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  fill: silver;
  vertical-align: middle;
}
