
.optimize-button {
    position: absolute;
    right: 2vw;
    z-index: 15;
    bottom: 10vh;

    border-radius: 4px;
    border: 1px silver solid;
    background: white;
}
