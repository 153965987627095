.node-menu-container{
    position: absolute;
    right: 2vw;
    /*top: 45vh;*/
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    border: 1px silver solid;
    background: white;
    z-index: 15;
}

.determine-mass-flow-rate-button {
    position: absolute;
    right: 2vw;
    z-index: 15;
    bottom: 10vh;

    border-radius: 4px;
    border: 1px silver solid;
    background: white;
}
